@import "./_normalize.scss";
@import "./_form.scss";
@import "./_grid.scss";
@import "./_dialog.scss";
@import "./media.scss";


$primary-color: #fa0;
$secondary-color: #ff6200;
$tertiary-color: #7b09d8;

$dark-white-color: #fff;
$light-white-color: #f9f9f9;
$dark-black-color: #7e7e7e;
$light-black-color: #cfcfcf;


:root,
html[data-theme="light"] {
	--accent-color: #fab131;
	--swiper-theme-color: #fab131 !important;
	--swiper-pagination-bullet-inactive-color: #000 !important;
	--background-color: #f5f5f5;
	--background-color-2: #fff;
	--background-color-3: #f4f4f4;
	--background-color-4: #f9f9f9;
	--background-color-5: #fbfbfb;
	--background-color-6: #f9f9f9;
	--background-color-7: #f4f4f4;
	--background-color-8: #fff;
	--background-color-9: #f4f4f4;
	--background-color-10: #ececec;
	--background-contrast: #fdfcff;
	--search-input: #ededed;
	--search-input-color: #181818;
	--background-invert: #fff;
	--auth-background: #fff;
	--input-color: #181818;
	--border-color: #eee;
	--highlight-color: #275efe;
	--foreground-color: #181818;
	--badge-color: #848484;
	--dark-color: #000000;
	--logo-filter: inherit;
	--glass: 250, 250, 250;
	--glass-opacity: 0.8;
	--primary-color: #275efe;
	--info-color: #11abfe;
	--success-color: #00d090;
	--warning-color: #ffc800;
	--danger-color: #ff3d71;
	--black: #000;
	--white: #fff;
	--text-dark: #243E56;
	--text-ligt-grey : #707070;
	--border-light-color: #e3dfdf;


	--red-orange: #FF6D2E;
	--btn-border-color: #E4E5EB;
	// ------ desktop view layout ------
	// @author : sanjay bixbytes @date : 09-05-2022
	--background-red: #DB4437;
	--background-blue: #4267B2;
	--after-verticle-line-color-20: #23262f33;
	--after-verticle-line-color-10: #23262f1a;
	--light-grey-color-opacity-60: #24262e99;
	--semi-dark-grey-color-opacity-60: #23262F99;
	--border-background-shadow: #0000001f;
	--button-background: #FF6D2E;



	--font-12: 12px;
	--font-14: 14px;
	--font-16: 16px;
	--font-18: 18px;
	--font-20: 20px;
	--font-24: 24px;
	@media (min-width: 991px) {
		--font-12: 14px;
		--font-14: 16px;
		--font-16: 18px;
		--font-18: 19px;
		--font-20: 22px;
		--font-24: 26px;
	}
}

.relative {
	position: relative;
}

.mx-auto {
	margin: 0 auto;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.text-center {
	text-align: center;
}

.text-white {
	color: white;
}

.text-black {
	color: black;
}

.text-primary {
	color: var(--primary);
}

.text-info {
	color: var(--info);
}

.text-success {
	color: var(--success);
}

.text-warning {
	color: var(--warning);
}

.text-danger {
	color: var(--danger);
}

.text-meta {
	color: var(--foreground-color);
	font-size: 0.94rem;
	opacity: 0.6;
}

.text-lead {
	color: var(--foreground-color);
	font-size: 1.17rem;
}

.divider {
	height: 1px;
	background-color: var(--border-color);
	margin-top: 20px;
	margin-bottom: 20px;

	&.no-margin {
		margin: 0;
	}
}

@for $i from 0 to 5 {
	.p-#{$i* 10} {
		padding: $i * 10px;
	}

	.m-#{$i* 10} {
		margin: $i * 10px;
	}

	.p-#{$i* 10} {
		padding: $i * 10px;
	}

	.p-t-#{$i* 10} {
		padding-top: $i * 10px;
	}

	.p-b-#{$i* 10} {
		padding-bottom: $i * 10px;
	}

	.p-l-#{$i* 10} {
		padding-left: $i * 10px;
	}

	.p-r-#{$i* 10} {
		padding-right: $i * 10px;
	}

	.m-#{$i* 10} {
		margin: $i * 10px;
	}

	.m-t-#{$i* 10} {
		margin-top: $i * 10px;
	}

	.m-b-#{$i* 10} {
		margin-bottom: $i * 10px;
	}

	.m-l-#{$i* 10} {
		margin-left: $i * 10px;
	}

	.m-r-#{$i* 10} {
		margin-right: $i * 10px;
	}
}
@mixin min($bp, $max: "null", $device: "screen") {
	@if $max== "null" {
		@media only #{$device} and (min-width: #{$bp}) {
			@content;
		}
	} @else {
		@media only #{$device} and (min-width: #{$bp}) and (max-width: #{$max}) {
			@content;
		}
	}
}

@function container($container-size, $true-val: false) {
	@return map-get($container-sizes, $container-size);
}

$breakpoints: (
	na: 0px,
	// For BS grid
	xs: 320px,
	// Smartphone
	sm: 600px,
	// Tablets
	md: 900px,
	// Tablets Landscape and small desktops
	lg: 1200px,
	// Desktops
	xl: 1800px,
	// Large Desktop
);
$container-sizes: (
	xs: map-get($breakpoints, xs) - 20px,
	sm: map-get($breakpoints, sm) - 30px,
	md: map-get($breakpoints, md) - 40px,
	lg: map-get($breakpoints, lg) - 50px,
	xl: map-get($breakpoints, xl) - 400px,
);

.container {
	padding-right: 1rem;
	padding-left: 1rem;

	&:not(.is-fluid) {
		margin: 0 auto;

		@each $bp, $container-size in $container-sizes {
			@include min(#{bp(#{$bp})}) {
				width: 100% !important;
				max-width: container(#{$bp});
			}
		}
	}

	&.is-fluid {
		padding-left: 40px;
		padding-right: 40px;
		padding-top: 20px;
	}
}

button:disabled {
  opacity: 0.8;
  cursor: not-allowed!important;
}

.jquery-modal {
  z-index: 999 !important;
}
.close-modal {
  display: none !important;
}
