@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");
@media (max-width: 480px) {

  .text {
    color: #adadad;
  }
  .user-card-details h1 {
    color: white;
  }

  .app-navigation-item.active .text {
    color: #ff6d2e !important;
  }

  .app-navigation-item.active-dash .text {
    color: #ff6d2e !important;
  }


  .map-design-style {
    padding: 16px;
    background-color: black;
  }
  .home .map .header {
    backdrop-filter: none !important;
  }

  .header .space-card-body-middle .title {
    text-shadow: 0px 2px 6px #000;
  }

  .header .space-card-header-background:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 85%;
    background: rgb(0 0 0 / 25%);
    border-radius: 15px;
  }

  .header .space-card-body-middle .title {
    text-shadow: 0px 2px 6px #000;
    bottom: 82px !important;
    font-size: 1rem !important;
  }

  .header .space-card {
    margin: 15px 15px 0 !important;
  }
  .accepted-in-days-deskttop {
    display: none !important;
  }

  .accepted-in-days-mobile,
  .accepted-in-days-text-mobile {
    display: block !important;
  }

  .upcoming-days {
    float: right;
    position: relative;
    bottom: 90px;
    color: white;
    text-shadow: 0px 2px 6px #000;
    font-size: 1rem;
    font-weight: 500;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .space-slider .swiper-slide-active {
    padding-left: 0px !important;
    margin-right: 0px !important;
  }

  .intro .intro-images .swiper-wrapper .swiper-slide-active {
    padding-left: 0px !important;
    margin-right: 0px !important;
  }
  .dynamic-swipe-style {
    margin-left: -24px;
    margin-right: 10px;
  }

  .faviourte-spaces-dynmic .swiper-wrapper .dynamic-swipe-style-liked {
    margin-left: -24px;
    margin-right: 10px;
  }

  .onboarding-intro-section
    .intro-head-section
    .swiper-wrapper
    .swiper-slide-active {
    padding-left: 0px !important;
    margin-right: 0px !important;
  }

  .home-regions-head
    .trending-home-box
    .popular-homes-main
    .swiper-wrapper
    .swiper-slide-active {
    padding-left: 0px !important;
    margin-right: 0px !important;
  }

  .trending-regions-head
    .trending-regions-box
    .popular-regions-main
    .swiper-wrapper
    .swiper-slide-active {
    padding-left: 0px !important;
    margin-right: 0px !important;
  }

  .trending-regions-dynamic-swipe-style {
    margin-left: -34px;
    margin-right: 10px;
  }
}

.accepted-in-days-deskttop {
  display: block;
}


