@import "./theme/base.scss";
@import "~bootstrap/scss/bootstrap.scss";
@import "~@angular/cdk/overlay-prebuilt.css";
@import "~src/assets/scss/bix-grid.scss";
@import "~src/assets/scss/bix-dropdown.scss";

// html[data-theme="dark"] {
// 	--accent-color: #fab131;
// 	--stripe-elements-box-shadow: "0 1px 3px 0 #ca103b";
// 	--swiper-theme-color: #fab131 !important;
// 	--swiper-pagination-bullet-inactive-color: #fff !important;
// 	--auth-background: #100f0f;
// 	--background-color: #100f0f;
// 	--background-color-2: #1a1a1a;
// 	--background-color-3: #1a1a1a;
// 	--background-color-4: #1a1a1a;
// 	--background-color-5: #1a1a1a;
// 	--background-color-6: #141414;
// 	--background-color-7: #2a2a2a;
// 	--background-color-8: #1a1a1a;
// 	--background-color-9: #2b2b2b;
// 	--background-color-10: #272727;
// 	--background-contrast: #100f0f;
// 	--background-invert: #1a1a1a;
// 	--search-input: #1a1a1a;
// 	--search-input-color: #f7f7f9;
// 	--border-color: #2a2b2d;
// 	--highlight-color: #275efe;
// 	--input-color: #fff;
// 	--badge-color: #fff;
// 	--foreground-color: #f7f7f9;
// 	--menu-add-color: #303030;
// 	--dark-color: #181818;
// 	--logo-filter: brightness(0) invert(1);
// 	--shade-loading: grayscale(2.8) brightness(0.12);
// 	--glass: 244, 244, 244;
// 	--glass-opacity: 0.9;
// 	--primary-color: #275efe;
// 	--info-color: #11abfe;
// 	--success-color: #00d090;
// 	--warning-color: #ffc800;
// 	--danger-color: #ff3d71;
// 	--black: #fff;
// 	--white: #000;
// }
input,
textarea,
select {
  font-size: inherit;
}

// Fix old styles
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

::selection {
  background-color: black;
  color: white;
}

::-webkit-scrollbar {
  display: none;
}

html,
body {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Fira Sans, Ubuntu, Oxygen, Oxygen Sans, Cantarell,
    Droid Sans, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol,
    Lucida Grande, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  background-color: var(--background-color);
  color: var(--foreground-color);
  padding: 0;
  margin: 0;
  // overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: scroll;
  /// height: var(--app-height);

  // ------ desktop view layout ------
  // @author : sanjay bixbytes @date : 09-05-2022
  @media (min-width: 1024px) {
    font-family: "Montserrat";
    overflow: auto;
    background-color: #ffffff !important;
  }
}

a {
  color: var(--foreground-color);
  text-decoration: none;

  &:focus,
  &:hover {
    color: var(--accent-color);
    cursor: pointer;
    text-decoration: none;
  }
}

.transformer {
  -webkit-tap-highlight-color: transparent;
  transform: translateZ(0);
  margin-right: 3px;
}

.cdk-overlay-backdrop {
  background-color: rgba(0, 0, 0, 0.76);
}

.custom-marker {
  font-size: 1rem;
  font-weight: 600;
  background-color: #fff;
  padding: 0.5rem 1rem;
  border: 1px solid rgba(152, 152, 152, 0.39);
  border-radius: 100px;

  .custom-marker-img {
    border: 1px solid white;
    border-radius: 50%;
    position: relative;
    z-index: 1;
  }
}
.custom-marker,
.custom-marker-request,
.custom-marker-like {
  cursor: pointer;
}

.mapboxgl-ctrl-attrib,
.mapboxgl-ctrl-logo {
  display: none !important;
}

.jvm-tooltip {
  color: #fff;
  border-radius: 15px;
  font-size: 14px;
  line-height: 1.2;
  // white-space: nowrap;

  background-color: #000;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 10px 0px;
  // animation: fadein 0.5s, fadeout 0.5s 2.5s;
  padding: 5px 7px;
  display: none;
  pointer-events: none;
}

.lazyload {
  transition: opacity 1s;
  opacity: 0;
  object-fit: cover;
}

.ng-lazyloaded {
  opacity: 1;
}

a[href^="http://maps.google.com/maps"]
{
  display: none !important;
}

a[href^="https://maps.google.com/maps"]
{
  display: none !important;
}

.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none;
}

.gmnoprint div {
  background: none !important;
}

#markerLayer {
  div {
    img {
      border: 3px solid white !important;
      border-radius: 50%;
    }
  }
}

[placeholder]:not(input):empty::before {
  content: attr(placeholder);
  color: #555;
}

[placeholder]:empty:focus::before {
  content: "";
}

.dropdown-action-item {
  font-size: 1.2rem;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;

  &:hover {
    background-color: var(--accent-color);
    color: white;
    cursor: pointer;
  }
}

.toast-style-design.toast-success {
  background-color: orange;
}

.orange-snackbar {
  background-color: orange;
}

.dropup-content {
  background-color: rgba(0, 0, 0, 0.8) !important;
  backdrop-filter: blur(50px) !important;
}

.dropdown-action-list {
  display: flex;
  flex-direction: column;
  box-shadow: rgba(17, 17, 18, 0.04) 0 2px 4px, rgba(19, 20, 20, 0.06) 0 1px 1px;

  a {
    color: white;

    &:first-of-type {
      //border-top-left-radius: 5px;
      //border-top-right-radius: 5px;
    }

    &:last-of-type {
      //border-bottom-left-radius: 5px;
      //border-bottom-right-radius: 5px;
    }

    padding: 15px;
    // white-space: nowrap;

    &:hover {
      background-color: var(--accent-color);

      color: #fff;
      cursor: pointer;
    }

    i {
      text-align: right;
      float: right;
    }
  }
}

@media all and (max-width: 1000px) {
  .container {
    padding-left: 0px !important;
    padding-right: 0px !important;
    min-width: 100% !important;
    max-width: 100% !important;
    width: 100% !important;
  }
}

.section-title {
  font-size: 1rem;
  font-weight: 400;
  padding: 7px 15px;
  display: inline-flex;
  align-items: center;
}

span.ripple {
  position: absolute;
  border-radius: 50%;
  will-change: transform, opacity;
  transform: scale(0);
  border-radius: 15px;
  animation: ripple 600ms linear;
  background-color: rgba(255, 255, 255, 0.7);
}

@keyframes ripple {
  to {
    transform: scale(4);

    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes slideY {
  0% {
    opacity: 0;
    transform: translateY(200px);
  }
}

@keyframes slideX {
  0% {
    opacity: 0;
    transform: translateX(-200px);
  }
}

.tooltip {
  position: absolute;
  z-index: 1000;
  padding: 10px;
  background-color: #18181b;
  color: #fff;
  border-radius: 15px;
  font-size: 14px;
  line-height: 1.2;
  // white-space: nowrap;

  background-color: #000;
  color: #fff;
  text-align: center;
  border-radius: 5px;

  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 10px 0px;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;

  visibility: hidden;
  opacity: 0;
  transition: opacity 0.1s ease-in-out, visibility 0.1s ease-in-out,
    transform 0.1s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  pointer-events: none;

  &.visible {
    visibility: visible;
    opacity: 1;
  }
}

[data-tooltip]:hover {
  position: relative;
}

[data-tooltip]:hover:after {
  background-color: #000000;
  border-radius: 5px;

  color: #ffffff;
  font-size: 12px;
  content: attr(data-tooltip);

  margin-bottom: 10px;
  top: calc(100% + 5px);
  left: 0;
  padding: 7px 12px;
  position: absolute;
  width: auto;
  min-width: 50px;
  max-width: 300px;
  line-height: 1.5;
  word-wrap: break-word;
  // white-space: nowrap;
  z-index: 9999;
}

// Line animation
.jvm-line[animation="true"] {
  -webkit-animation: jvm-line-animation 10s linear forwards infinite;
  animation: jvm-line-animation 10s linear forwards infinite;

  @keyframes jvm-line-animation {
    from {
      stroke-dashoffset: 250;
    }
  }
}

.jvm-container {
  touch-action: none;
  //position: relative;
  //overflow: hidden;
  //height: 100%;
  //width: 100%;
}

.jvm-zoom-btn {
  border-radius: 3px;
  background-color: var(--background-color-9);
  padding: 3px;
  box-sizing: border-box;
  position: absolute;
  line-height: 10px;
  cursor: pointer;
  height: 20px;
  width: 20px;
  left: 10px;
  display: grid;
  place-content: center;

  &.jvm-zoomout {
    top: 40px;
  }

  &.jvm-zoomin {
    top: 10px;
  }
}

.section .free_plan {
  padding: 0px 15px;
  border-radius: 15px;
  box-shadow: 0 3px 3px 0 rgb(0 0 0 / 5%), 0 5px 15px 0 rgb(0 0 0 / 5%);
  position: relative;
  overflow: hidden;
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #fab131;
  justify-content: space-between;
  margin-top: 10px;
}

span.em_text {
  font-weight: 400;
  text-transform: capitalize;
  font-size: 16px;
}

.subscriptions .active-plan.ng-star-inserted {
  max-width: 100%;
  min-width: inherit !important;
}

app-subscriptions.modal-content.ng-star-inserted {
  display: block;
  overflow-y: scroll;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 103;
}

@media (min-width: 1024px) {
  app-subscriptions.modal-content.ng-star-inserted {
    height: calc(100vh - 68px);
  }
}

.subscribe .section {
  opacity: 1 !important;
}

.active-plan.ng-star-inserted {
  color: #181818;
}

.subscribe .options .option .details .name,
.subscribe .options .option .details .price {
  font-size: 15px !important;
}

h4.space-text {
  position: absolute;
  z-index: 2222;
  top: -22px;
  height: 100%;
  background: #fab131;
  width: 100%;
  padding: 5%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

// @media (min-width: 380px) {
// 	.swiper-slide {
// 		padding-left: 30px;
// 	}
// 	}

.ui-slider-handle {
  background: #06b9c0 !important;
}

// ------ desktop view layout ------
// @author : sanjay bixbytes @date : 18-05-2022

@media (min-width: 1024px) {
  .modal-content {
    border: none !important;
    border-radius: 0px !important;
    display: block !important;
  }

  .dp-calendar-weekday {
    width: 40px !important;
  }

  .dp-calendar-day {
    width: 40px !important;
    height: 40px !important;
  }
  button {
    cursor: pointer !important;
  }
  a {
    cursor: pointer !important;
  }
}

.ngx-pagination .current {
  background: orange !important;
}

.mx-10 {
  margin-left: 10px;
  margin-right: 10px;
}

.my-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.my-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.mt-10 {
  margin-top: 10px !important;
}

.ml-16 {
  margin-left: 16px;
}

.mr-10 {
  margin-right: 10px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-13 {
  margin-bottom: 13px;
}

.main-content {
  margin-left: 225px;
  margin-top: 20px;
  padding: 0px 20px;
  @media (max-width: 1090px) {
    margin-left: 65px;
  }
  @media (max-width: 672px) {
    margin-left: 0px;
  }
}

.ngx-select {
  &.dropdown {
    border: none;
  }
}

.ngx-select_multiple {
  padding: 0px !important;

  & .ngx-select__search {
    margin-bottom: 0px !important;
    height: unset !important;
  }
}

.float_right {
  float: right;
}

@media (min-width: 1024px) {
  .container {
    max-width: 840px;
    position: relative;
  }
}

::ng-deep {
  @media (min-width: 1024px) {
    .table-data-section {
      width: 85% !important;
    }
  }
}

// ----- global fix ------
// Ticket :::::: HSV2-906 :::::
.popular_title {
  .card-title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    // white-space: pre-wrap;
    // word-break: break-word !important;
  }
}

.toast-container {
  .toast-error {
    background-color: #bd362f !important;
  }
}

.share_textBox {
  display: flex;
  align-items: center;
  margin-bottom: 23px;
  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0px !important;
    color: #23262f;
    padding-left: 15px;
    text-align: left;
    span {
      font-weight: 600;
      color: #ffa300;
    }
  }
}

// add home popup css
.bix__modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  z-index: 99 !important;
  background: rgba(0, 0, 0, 0.2);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  @media (max-width: 1024px) {
    z-index: 99;
  }
}
.add-home-form-modal {
  width: 520px;
  height: 100%;
  max-width: 100%;
  margin: 0px;
  overflow: hidden;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  position: fixed;
  background-color: #f8f8f8;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgb(17 17 18 / 4%), 0 1px 1px rgb(19 20 20 / 6%);
  font-family: "Montserrat", sans-serif;
  @media (min-width: 1024px) {
    height: 90%;

    &.hide {
      display: none;
    }
  }
  .close-section {
    margin-bottom: 45px;
    position: absolute;
    right: 25px;
    top: 20px;
    .skip-and-close {
      .close-popup {
        cursor: pointer;
        float: right;
        font-size: 20px;
      }
    }
  }
}

@-moz-document url-prefix() {
  body {
    scrollbar-width: none !important;
  }
  div {
    scrollbar-width: none !important;
  }
}

.view-loading {
  & > * {
    display: none;
    color: transparent !important;
  }
  // &:empty {
  color: transparent !important;
  animation: loading 1.1s infinite linear;
  background: #dedfe1 !important;
  background-image: -webkit-linear-gradient(
    to right,
    #dedfe1 0%,
    #f2f3f5 20%,
    #dedfe1 40%,
    #dedfe1 100%
  ) !important;
  background-image: linear-gradient(
    to right,
    #dedfe1 0%,
    #f2f3f5 20%,
    #dedfe1 40%,
    #dedfe1 100%
  ) !important;
  background-repeat: no-repeat;
  // }
}

@keyframes loading {
  0% {
    background-position: -100px;
  }
  100% {
    background-position: 200px;
  }
}

.hs_btn {
  outline: none;
  border-radius: 8px !important;
  padding: 12px;
  font-size: var(--font-12);
  line-height: 17px;
  font-weight: 700;
  border: none;
  overflow: hidden;
  &--primary {
    min-width: 100%;
    background-color: #ff6d2e;
    color: #ffffff;
    font-size: var(--font-16);
    line-height: var(--font-18);
    padding: 15px;
  }
  &--secondary {
    min-width: 90px;
    background-color: #ffa300;
    color: #ffffff;
  }
}

.e-daterangepicker {
  &.e-popup {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
  }
  .e-calendar {
    max-width: 100% !important;
    .e-start-date.e-selected {
      border-radius: 20px 0px 0px 20px !important;
    }
    .e-end-date.e-selected {
      border-radius: 0px 20px 20px 0px !important;
    }
  }
  .e-range-hover {
    background-color: #ffa300 !important;
    color: #ffffff !important;
    span {
      background-color: #ffa300 !important;
      color: #ffffff !important;
    }
  }
  .e-other-month.e-range-hover {
    background-color: #ffffff !important;
  }
  .e-start-date.e-selected {
    span {
      background-color: #ffa300 !important;
      color: #ffffff !important;
    }
  }
  .e-end-date.e-selected {
    span {
      background-color: #ffa300 !important;
      color: #ffffff !important;
    }
  }
  .e-calendar .e-content td.e-selected span.e-day {
    border-radius: 5px !important;
    color: #ffffff !important;
  }
  .e-calendar .e-content td {
    font-size: 14px !important;
    font-weight: 600 !important;
  }
  .e-calendar .e-content span.e-day {
    font-size: 14px !important;
    font-weight: 600 !important;
  }
  .e-day-span {
    display: none;
  }
  .e-separator {
    margin-top: 12px !important;
  }
  .e-cell.e-other-month.e-disabled {
    background-color: unset !important;
    color: unset !important;
    span.e-day {
      color: unset !important;
    }
  }
  .e-cell.e-disabled {
    background-color: unset;
    color: rgb(176, 176, 176) !important;
    opacity: 1 !important;
    span.e-day {
      color: rgb(176, 176, 176) !important;
      text-decoration: line-through;
    }
  }

  .e-booked-dates {
    span {
      color: #f87373 !important;
    }
  }
}

.e-daterangepick-mob-popup-wrap {
  .e-daterangepicker.e-popup {
    max-width: calc(100% - 30px) !important;
    width: 100%;
    position: unset !important;
    top: unset !important;
    left: unset !important;
    transform: unset;
  }
  .e-date-range-container {
    width: 100%;
  }
  .e-calendar {
    max-width: 100% !important;
    .e-start-date.e-selected {
      border-radius: 30px 0px 0px 30px !important;
    }
    .e-end-date.e-selected {
      border-radius: 0px 30px 30px 0px !important;
    }
  }
  .e-btn {
    border-radius: 5px;
    border: none;
    outline: none;
  }

  .e-day {
    width: 48px !important;
    height: 48px !important;
    line-height: 48px !important;
  }
  .e-range-hover {
    background-color: #ffa300 !important;
    color: #ffffff !important;
    span {
      background-color: #ffa300 !important;
      color: #ffffff !important;
    }
  }
  .e-start-date.e-selected.e-range-hover {
    span {
      background-color: transparent !important;
      color: #ffffff !important;
    }
  }
  .e-end-date.e-selected.e-range-hover {
    span {
      background-color: transparent !important;
      color: #ffffff !important;
    }
  }
  .e-calendar .e-content td.e-selected span.e-day {
    border-radius: 5px !important;
    color: #ffffff !important;
  }
  .e-calendar .e-content td {
    font-size: 16px !important;
    font-weight: 600 !important;
  }
  .e-calendar .e-content span.e-day {
    font-size: 16px !important;
    font-weight: 600 !important;
  }
}

.token-chart {
  // width: 100px !important;
  svg {
    width: 100px !important;
    .apexcharts-inner {
      transform: translate(0px, 0px) !important;
    }
  }
  .apexcharts-inner {
    transform: translate(0px, 0px) !important;
  }
}

.pac-container {
  z-index: 99999 !important;
}

.pac-container {
  width: 440px !important;
  margin-top: 8px !important;
  border-radius: 10px;

  @media (max-width: 580px) {
    width: 100% !important;
    padding: 15px 0;
    box-shadow: none;
    border: none;
    left: 0 !important;
    margin-top: 0 !important;
  }
}

.pac-container .pac-item {
  padding: 2px 20px !important;
  border: none;
}

.pac-icon {
  background: url("/assets/images/Location.svg") no-repeat !important;
  width: 16px;
  height: 16px;
}

.primary-button {
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  background-color: #ff6200;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  color: white;
  width: 100%;
  cursor: pointer;
}

.solid-outline-button {
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid #e9e9ea;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  color: rgb(118, 123, 138);
  width: 100%;
  cursor: pointer;
}

.map-home-child {
  position: absolute;
  background: white;
  top: 40px;
  left: -30px;
  border-radius: 16px;
  box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.14);
  z-index: 108;
  padding: 12px;
  .mark-card {
    position: relative;
  }
  .mark-close {
    cursor: pointer;
    position: absolute !important;
    border: none;
    font-size: 15px !important;
    z-index: 10;
    border-radius: 100% !important;
    top: 8px;
    left: 8px;
    font-weight: 400;
    color: #000;
    background: white !important;
    height: 24px;
    width: 24px;
  }
  .mark-card-link {
    cursor: pointer;
    display: flex;
    text-decoration: none;
  }
  .mark-card-link-image {
    height: 120px;
    width: 120px;
    border-radius: 8px;
    display: inline-block;
    overflow: hidden;
    pointer-events: none;
    position: relative;
  }
  .mark-card-link-img {
    display: block;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    width: 100%;
  }
  .mark-card-content {
    padding-left: 16px;
    width: 270px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .mark-card-title {
    color: black;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .mark-card-location {
    color: #767b8a;
    font-weight: 400;
    font-size: 14px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    max-width: 18rem;
    -webkit-box-orient: vertical;
    line-height: 20px;
    overflow: hidden;
  }
  .mark-card-summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .mark-card-price {
    color: #23262f;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .mark-card-des {
    color: #23262f;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .mark-card-heart {
    display: flex;
    align-items: center;
    i {
      color: #ff6d2e;
    }
  }
}

.map-mark-active {
  border: 2px solid #ff6d2e;
}
