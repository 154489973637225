.dialog {
	.icon {
		margin: 0 auto;
		width: 40px;
		height: 40px;
		border-radius: 50%;
		/* background: var(--accent-color); */
		display: grid;
		place-content: center;
		margin-top: 10px;
	}
	.title {
		padding: 15px;
		text-align: center;
		font-size: 1.2rem;
		font-weight: 500;
	}

	.body {
		padding: 15px;
	}

	.actions {
		display: flex;
		flex-direction: row;
		margin: 15px;
		padding-top: 15px;
		border-top: 1px solid var(--border-color);
		margin-bottom: calc(15px + env(safe-area-inset-bottom));
		.action {
			&.transparent {
				background-color: transparent;
			}
			background-color: var(--background-color-10);
			// color: white;
			flex-grow: 1;
			flex-basis: 0;
			transition: transform 0.4s ease-in-out;
			box-shadow: 0 2px 4px rgba(17, 17, 18, 0.04), 0 1px 1px rgba(19, 20, 20, 0.06);
			padding: 10px 20px;
			border-radius: 50px;
			text-align: center;
			position: relative;
			overflow: hidden;

			&.highlight {
				background-color: var(--accent-color);
				color: white;
			}
			& + .action {
				margin-left: 15px;
			}
		}
	}
}
