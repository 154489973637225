

.features.ng-star-inserted li {
    font-size: 16px !important;
}

.home-delete-modal-main a {
    top: 8px !important;
    right: 8px !important;
  }
  